import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { generateId } from 'frontastic-common'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import ClientFactory from '../../../../services/algolia/ClientFactory'
import StoreMap from './StoreMap'
import FullPageWidthWrapper from '../../../molecules/Layout/FullPageWidthWrapper'
import SearchForm from './SearchBox'
import StoreSlider from './StoreSlider'
import StoreService from '../../../../services/algolia/StoreService'
import Button from '../../../atoms/button/Button'
import { ReactComponent as IconEyeOpen } from '../../../../../icons/sport2000-icons/eye-open-store.svg'
import { ReactComponent as IconEyeClose } from '../../../../../icons/sport2000-icons/eye-closed-store.svg'
import FilterStoreService from '../../../../services/algolia/FilterStoreService'

const getCurrentRefinementByUrl = () => {
    let result = []
    const searchParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '')
    const searchQuery = searchParams.get('logo')

    switch (searchQuery) {
        case 'absolute-teamsport':
            result = [{
                attribute: 'specialization_logos',
                value: 'absolute_teamsport',
                label: 'absolute_teamsport',
            }]
            break
        case 'running-experts':
        case 'absolute-run':
            result = [{
                attribute: 'specialization_logos',
                value: 'running_experts',
                label: 'running_experts',
            }, {
                attribute: 'specialization_logos',
                value: 'absolute_run',
                label: 'absolute_run',
            }]
            break
        default:
            break
    }

    return result
}

const StoreFinder = ({
    context, data, heightHeader, offsetY,
}) => {
    const {
        lat, lng, filterConfiguration, gln, radius, isUsedInStore,
    } = data

    const FILTERING_RADIUS_KM = radius ? radius * 1000 : 20000
    const INIT_RADIUS_KM = isUsedInStore ? FILTERING_RADIUS_KM : 1000000
    const initCurrentRefinement = getCurrentRefinementByUrl()
    const isInSpecialPage = initCurrentRefinement.length > 0

    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedStore, setSelectedStore] = useState(null)
    const [isShowSlider, setIsShowSlider] = useState(false)
    const [hasScript, setHasScript] = useState(false)
    const [isActiveFilter, setIsActiveFilter] = useState(false)
    const [isActiveSearch, setIsActiveSearch] = useState(false)
    const [zoom, setZoom] = useState(isUsedInStore ? 12 : 7)
    const [center, setCenter] = useState([lat, lng])
    const [storeSlider, setStoreSlider] = useState([])
    const [stickLocation, setStickLocation] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [stores, setStores] = useState([])
    const [facets, setFacets] = useState(null)
    const [attributeToRender, setAttributeToRender] = useState([])
    const [currentRefinement, setCurrentRefinement] = useState(initCurrentRefinement)
    const isMobile = useDeviceType() === 'mobile'
    const shouldResetWithCurrentRefinementState = isInSpecialPage ? true : !currentRefinement.length

    const shouldReset = shouldResetWithCurrentRefinementState
        && (!selectedLocation || (selectedLocation && selectedLocation.lat === lat && selectedLocation.lng === lng))
        && isActiveSearch
        && !selectedLocation

    const defaultCenter = [lat, lng]
    const apiKey = context.project.configuration.googleMap.mapKey
    const attrGLN = StoreService.formatGLN(gln)
    const attrFacet = StoreService.formatFacetFilterFromStudio(filterConfiguration)
    const initFacetFilter = FilterStoreService.formatFacetFilter(currentRefinement)
    const baseQueryOptions = {
        aroundLatLng: selectedLocation ? `${selectedLocation.lat}, ${selectedLocation.lng}` : `${lat}, ${lng}`,
        aroundRadius: selectedLocation ? FILTERING_RADIUS_KM : INIT_RADIUS_KM,
        hitsPerPage: 3000,
        maxValuesPerFacet: 20,
        facets: ['*'],
    }

    const initQueryOptions = {
        ...baseQueryOptions,
        facetFilters: [
            attrGLN,
            ...attrFacet,
            initFacetFilter,
        ],
    }

    const [queryOptions, setQueryOptions] = useState(initQueryOptions)

    const handleSelectStore = (hit) => {
        setIsShowSlider(true)
        const tmpLat = hit._geoloc ? hit._geoloc.lat : hit.lat
        const tmpLng = hit._geoloc ? hit._geoloc.lng : hit.lng

        if (!isActiveSearch && hit.isFromMap) {
            ClientFactory.queryStore(context, '', {
                aroundLatLng: `${parseFloat(tmpLat)}, ${parseFloat(tmpLng)}`,
                hitsPerPage: 15,
                aroundRadius: FILTERING_RADIUS_KM,
                facetFilters: [
                    attrGLN,
                    ...attrFacet,
                    initFacetFilter,
                ],
            }).then((res) => {
                setStoreSlider(res.results[0]?.hits)

                setStickLocation({
                    lat: parseFloat(tmpLat),
                    lng: parseFloat(tmpLng),
                })
            })
        }

        setSelectedStore({
            lat: parseFloat(tmpLat),
            lng: parseFloat(tmpLng),
            logo: hit.specialization_logos?.length && hit.specialization_logos[0],
            objectId: hit.objectID || '',
            isFromMap: hit.isFromMap,
            isFromSlider: hit.isFromSlider,
        })
    }

    const handleSearch = (hit) => {
        setIsActiveSearch(true)
        setIsShowSlider(true)

        if (hit && hit.name) {
            setSelectedLocation({
                lat: parseFloat(hit._geoloc.lat),
                lng: parseFloat(hit._geoloc.lng),
                objectId: hit.objectID,
            })
            setSelectedStore({
                lat: parseFloat(hit._geoloc.lat),
                lng: parseFloat(hit._geoloc.lng),
                logo: hit.specialization_logos?.length && hit.specialization_logos[0],
                objectId: hit.objectID,
                zoom: 16,
            })
            return
        }

        if (stores && stores.length && selectedLocation) {
            const indexSelectedLocation = stores.findIndex((store) => parseFloat(store._geoloc.lat) === selectedLocation.lat
                && parseFloat(store._geoloc.lng) === selectedLocation.lng)

            if (indexSelectedLocation !== -1) {
                setSelectedStore({
                    lat: parseFloat(hit._geoloc.lat),
                    lng: parseFloat(hit._geoloc.lng),
                    objectId: generateId(),
                    zoom: 16,
                })
            }
        }

        setSelectedLocation({
            lat: parseFloat(hit._geoloc.lat),
            lng: parseFloat(hit._geoloc.lng),
        })
    }

    const handleRemove = () => {
        setSelectedLocation(null)
        setSelectedStore(null)
    }

    const handleReset = () => {
        setQueryOptions(initQueryOptions)
        setIsActiveSearch(false)
        setSelectedLocation(null)
        setSelectedStore(null)
        setZoom(7)
        setIsShowSlider(false)
        setStickLocation(null)
        setStoreSlider([])
    }

    const handleFocus = () => {
        if (isMobile) {
            setIsShowSlider(false)
        }
    }

    const handleUpdateCurrentRefinement = (item) => {
        setIsActiveSearch(true)

        if (!item) {
            setCurrentRefinement(initCurrentRefinement)
            return
        }

        const updatedCurrentRefinement = FilterStoreService.updateCurrentRefinement(currentRefinement, item)
        setCurrentRefinement(updatedCurrentRefinement)
    }

    const handleUpdateMap = () => {
        const formatedFacetFilter = FilterStoreService.formatFacetFilter(currentRefinement, attributeToRender)
        setQueryOptions((prev) => ({
            ...prev,
            facetFilters: formatedFacetFilter && formatedFacetFilter.length ? [
                formatedFacetFilter,
            ] : [
                attrGLN,
                ...attrFacet,
            ],
        }))
    }

    useEffect(() => {
        if (currentRefinement?.length) {
            handleUpdateMap()
        }
    }, [])

    useEffect(() => {
        if (shouldReset) {
            handleReset()
            return
        }

        if (isActiveSearch) {
            setIsShowSlider(true)
            setSelectedStore(null)
            handleUpdateMap()
        }
    }, [currentRefinement])

    useEffect(() => {
        const body = document.querySelector('body')
        body.classList.add('wrapper-page-store-finder')

        const interval = setInterval(() => {
            if (window.google?.maps) {
                setHasScript(true)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        let mounted = true
        ClientFactory.queryStore(context, '', queryOptions, baseQueryOptions).then((response) => {
            if (mounted) {
                setStores(response.results[0]?.hits)

                // always show all filter options
                setFacets(response.results[1]?.facets)
                setAttributeToRender(response.results[1]?.renderingContent?.facetOrdering)
            }
        })

        return () => {
            mounted = false
        }
    }, [queryOptions])

    useEffect(() => {
        if (shouldReset) {
            handleReset()
            return
        }

        if (selectedLocation) {
            setIsActiveSearch(true)
            setIsShowSlider(true)
            setQueryOptions((prev) => ({
                ...prev,
                hitsPerPage: 15,
                aroundLatLng: `${selectedLocation.lat}, ${selectedLocation.lng}`,
                aroundRadius: FILTERING_RADIUS_KM,
            }))
        }
    }, [selectedLocation])

    if (!hasScript) {
        return null
    }

    return (
        <FullPageWidthWrapper customClasses={'store-finder--wrapper'}>
            {(isActiveSearch || storeSlider.length > 0) && (
                <Button
                    className={'store-finder--btn-toggle-slider btn btn-default dark-mode'}
                    type={'button'}
                    onClick={() => {
                        if (!isShowSlider && isActiveFilter) {
                            setIsActiveFilter(false)
                        }
                        setIsShowSlider(!isShowSlider)
                    }}
                >
                    {isShowSlider ? (
                        <>
                            <IconEyeOpen width={34} height={23} />
                            <FormattedMessage id={'store.hide'} />
                        </>
                    ) : (
                        <>
                            <IconEyeClose width={34} height={23} />
                            <FormattedMessage id={'store.show'} />
                        </>
                    )}
                </Button>
            )}
            <SearchForm
                setSelectedLocation={setSelectedLocation}
                setSelectedStore={setSelectedStore}
                setIsShowSlider={setIsShowSlider}
                setIsActiveFilter={setIsActiveFilter}
                isActiveFilter={isActiveFilter}
                selectedLocation={selectedLocation}
                context={context}
                setIsActiveSearch={setIsActiveSearch}
                onSelect={(hit) => handleSearch(hit)}
                onRemove={handleRemove}
                onReset={handleReset}
                onFocus={handleFocus}
                value={searchValue}
                onChange={(val) => setSearchValue(val)}
                facets={facets}
                attributeToRender={attributeToRender}
                refine={(item) => handleUpdateCurrentRefinement(item)}
                currentRefinement={currentRefinement}
                initFacetFilter={initFacetFilter}
                isInSpecialPage={isInSpecialPage}
            />
            <StoreMap
                context={context}
                data={data}
                selectedStore={selectedStore}
                setIsShowSlider={setIsShowSlider}
                isShowSlider={isShowSlider}
                zoom={zoom}
                setZoom={setZoom}
                center={center}
                setCenter={setCenter}
                onSelect={(hit) => handleSelectStore(hit)}
                isActiveSearch={isActiveSearch}
                stores={stores}
                selectedLocation={selectedLocation}
                defaultCenter={defaultCenter}
                heightHeader={heightHeader}
                offsetY={offsetY}
            />
            <StoreSlider
                selectedLocation={selectedLocation}
                setSelectedStore={setSelectedStore}
                selectedStore={selectedStore}
                isActiveFilter={isActiveFilter}
                setIsActiveFilter={setIsActiveFilter}
                isShowSlider={isShowSlider}
                storeSlider={storeSlider}
                isActiveSearch={isActiveSearch}
                onSelect={(hit) => handleSelectStore(hit)}
                stickyLocation={stickLocation}
                data={data}
                stores={stores}
                context={context}
            />
        </FullPageWidthWrapper>
    )
}

StoreFinder.propTypes = {
    context: PropTypes.any,
    data: PropTypes.any,
    offsetY: PropTypes.any,
    heightHeader: PropTypes.any,
}

export default injectIntl(StoreFinder)
