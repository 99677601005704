import React from 'react'
import { useSelector } from 'react-redux'
import PanelButton from '../../../../Filter/PanelButton'
import useComponentVisible from '../../../../../../../utils/hooks/UseComponentVisible'
import { List } from './List'
import FilterStoreService from '../../../../../../../services/algolia/FilterStoreService'

export type Props = {
    attribute: string
    isLogo?: boolean
    isActiveFilter?: boolean
    refine: (value: string[]) => any
    currentRefinement: object | null
    facetDisplay: any
} & any

const Select: React.FC<Props> = ({
    facets, attribute, isLogo, isActiveFilter, refine, currentRefinement, facetDisplay,
}: Props) => {
    const activeFilter = useSelector((state) => state['product-list-algolia'].openFilter)
    const isOpen = activeFilter === attribute
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(isOpen)
    const items = FilterStoreService.getFacetItemsByAttribute(facets, attribute, facetDisplay)

    if (!items || !items.length) {
        return null
    }

    return (
        <div
            ref={ref}
            className={'filter-algolia--panel-wrapper'}
        >
            <PanelButton
                attribute={attribute}
                isOpen={isOpen}
                isComponentVisible={isComponentVisible}
                setIsComponentVisible={setIsComponentVisible}
                isActiveFilter={isActiveFilter}
            />

            {isOpen && isComponentVisible && (
                <div className={'filter-algolia--panel-values'}>
                    <List
                        items={items}
                        currentRefinement={currentRefinement}
                        refine={refine}
                        attribute={attribute}
                        isLogo={isLogo}
                    />
                </div>
            )}
        </div>
    )
}

export default Select
